import * as React from 'react'

const ContactForm = () => {
    return (
        <form name="Contatto AllVoip" data-netlify="true" method="post" netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="Contatto AllVoip" />
            <label htmlFor="nome" className="block mb-4">
                <span className="text-sm">Nome</span>
                <input required id="nome" type="text" name="nome" className="block w-full mt-1" />
            </label>

            <label htmlFor="email" className="block mb-4">
                <span className="text-sm">Indirizzo email</span>
                <input required id="email" type="email" name="email" className="block w-full mt-1" />
            </label>

            <label htmlFor="messaggio" className="block mb-4">
                <span className="text-sm">Messaggio</span>
                <textarea id="messaggio" nome="messaggio" required className="block w-full mt-1" />
            </label>

            <label htmlFor="consenso" className="block mb-4">
                <input type="checkbox" required name="consenso" /> <span className="text-sm">Autorizzo il trattamento dati personali come previsto dalla <a href="https://www.iubenda.com/privacy-policy/8074034" target="_blank" className="text-red-500 hover:text-red-600" rel="noopener noreferrer">Privacy Policy</a></span>
            </label>
           
            <button className="bg-green-400 text-white uppercase text-sm px-4 py-2 rounded-md mt-4" type="submit">
                Invia
            </button>
        </form>
    )
}

export default ContactForm

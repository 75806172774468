import * as React from 'react'
import { LightningBoltIcon, CloudIcon, GlobeAltIcon, StarIcon, CheckIcon, XIcon } from '@heroicons/react/outline'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { Dialog } from '@headlessui/react'
import { useState, useRef } from 'react'
import ContactForm from '../components/form'
import Seo from '../components/seo'

const IndexPage = () => {

  let [isOpen, setIsOpen] = useState(false)
  let closeButton = useRef(null)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
    <Seo />
    <main>

      <Dialog open={isOpen} onClose={closeModal} initialFocus={closeButton} className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
          <div className="bg-white relative p-8 pt-12 rounded-xl">
            <button ref={closeButton} onClick={closeModal} className="absolute top-3 right-3" aria-label="Chiudi Modulo"><XIcon className="w-6 h-6" /></button>
            <Dialog.Title className="text-2xl mb-4">Contattaci per maggiori informazioni</Dialog.Title>
            <ContactForm />
          </div>
        </div>
      </Dialog>

      <section className="header">
        <div className="container mx-auto px-6 py-4 flex flex-wrap items-center justify-center lg:justify-between gap-4">
          <div className="w-full lg:w-1/5 text-center lg:text-left">
            <StaticImage src="../images/logo-all-voip-voispeed-tecno.png" alt="logo allVoip" placeholder="none" />
          </div>

          <div className="">
            <nav>
              <ul className="flex items-center space-x-4 text-sm lg:text-base lg:space-x-8 uppercase">
                <li><Link to="/">Home</Link></li>
                <button className="uppercase" onClick={() => scrollTo('#soluzione')}>Soluzione</button>
                <button className="uppercase" onClick={() => scrollTo('#gui')}>GUI</button>
                <li><a href="https://cascinanet.bman.shop/all/3/Telefonia" alt="AllVoip Shop" target="_blank" rel="noopener noreferrer">Shop</a></li>
                <button type="button" className="uppercase" onClick={openModal}>Contatti</button>
              </ul>
            </nav>
          </div>
        </div>
      </section>

      <section className="bg-gradient-to-r from-green-400 to-green-500">
        <div className="container mx-auto py-12 lg:py-32 px-6 text-white flex flex-col items-center">
          <h1 className="text-4xl font-light mb-3">Prova subito la flessibilità del centralino VOIspeed, contattaci ora!</h1>
          <h2 className="text-5xl font-bold font-serif mb-6">6 € al mese per interno*</h2>
          <span className="block font-bold mb-6">Richiedi un preventivo, sono previsti sconti in base al numero di interni</span>
          <span className="block text-xs mb-12">* I prezzi si intendono IVA esclusa e non comprendono il costi di attivazione (una tantum) di 100 € IVA esclusa, durata minima del contratto 12 mesi</span>
          <button type="button" onClick={openModal} className="uppercase bg-red-600 px-6 py-3 rounded-md hover:bg-red-700 transition-colors">Richiedi subito un contatto</button>
        </div>
      </section>

      <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
        <div className="box-1 bg-gray-500 p-6 text-white text-center flex flex-col items-center">
          <LightningBoltIcon className="h-12 w-12 mb-6" />
          <h3 className="uppercase text-lg mb-2">Compatibilità totale</h3>
          <p>L’architettura innovativa del software permette di utilizzare le linee telefoniche, ISDN e VOIP già presenti in azienda, senza passare dalla rete internet.</p>
        </div>

        <div className="box-1 bg-gray-600 p-6 text-white text-center flex flex-col items-center">
          <CloudIcon className="h-12 w-12 mb-6" />
          <h3 className="uppercase text-lg mb-2">In cloud</h3>
          <p>Il centralino virtuale in cloud può integrare più sedi e uffici distaccati come se fossero una sede unica, senza la necessità di installare complicati software sul proprio server.</p>
        </div>

        <div className="box-1 bg-gray-700 p-6 text-white text-center flex flex-col items-center">
          <GlobeAltIcon className="h-12 w-12 mb-6" />
          <h3 className="uppercase text-lg mb-2">Lavorare ovunque</h3>
          <p>Gli stessi strumenti sempre a disposizione sia se si ha davanti un computer fisso, un portatile o anche uno smartphone e un tablet. La nuova interfaccia ti permette di operare ovunque.</p>
        </div>

        <div className="box-1 bg-gray-800 p-6 text-white text-center flex flex-col items-center">
          <StarIcon className="h-12 w-12 mb-6" />
          <h3 className="uppercase text-lg mb-2">I vantaggi del PBX virtuale</h3>
          <p>Il centralino virtuale semplifica la gestione del lavoro: report, rubriche condivise, personalizzazioni e caselle vocali sono alcuni dei vantaggi che aumentano la produttività dell’intera azienda.</p>
        </div>
      </section>

      <section className="bg-gradient-to-r from-yellow-600 to-red-700">
        <div className="container px-6 py-12 mx-auto flex gap-8 items-center flex-wrap">
          <div className="w-full lg:w-5/12">
            <StaticImage src="../images/tcall.png" alt="tCall software" placeholder="blurred" />
          </div>
          <div className="text-white space-y-3 flex-1">
            <h2 className="text-2xl font-light">Quanto vale la tua consulenza telefonica?</h2>
            <p>Scopri come farti pagare le tue consulenze da remoto con tCall, lo strumento online in grado di dare il giusto valore al tempo impiegato per il supporto telefonico!</p>
            <a href="https://www.tcall.it/" target="_blank" rel="noopener noreferrer" className="bg-white px-6 py-3 rounded-full text-gray-700 inline-flex hover:bg-gray-900 hover:text-white transition-colors">Scopri tCall</a>
          </div>
        </div>
      </section>

      <section className="bg-gray-700">
        <div className="container mx-auto px-6 py-12 flex gap-8 items-center flex-wrap">
          <div className="lg:w-3/12">
            <StaticImage className="rounded-xl" src="../images/ucloud.jpg" alt="uCloud software" placeholder="blurred" />
          </div>
          <div className="text-white space-y-3 flex-1">
            <h2 className="text-2xl font-bold">UCloud</h2>
            <p className="text-2xl font-light">Il primo centralino Cloud che può utilizzare le linee telefoniche già presenti nella tua azienda.</p>
            <p><b>Con VOIspeed UCloud la TeamSystem Communication è in grado di offrire oggi la prima soluzione di telefonia cloud peer-to-peer al mondo, frutto di un lungo processo di sviluppo e perfezionamento.</b></p>
            <p>VOIspeed UCloud si basa su un’architettura appositamente pensata per il funzionamento del servizio telefonico sulla nuvola; la necessità di pensare non più ad un centralino monolitico ma ad un sistema distribuito di servizi e processi ha portato allo sviluppo di una soluzione unica. UCloud ha una struttura tale da permettere a tutti gli elementi del servizio telefonico di dialogare fra di loro tramite servizi creati appositamente per lavorare in maniera indipendente e, allo stesso tempo, mantenere coerente l’intero sistema e tutti i suoi stati. Ne deriva un impianto assolutamente funzionale dove ogni elemento è un ingranaggio di una macchina complessiva robusta e altamente ridondata.</p>
          </div>
        </div>
      </section>

      <section className="bg-gray-100" id="soluzione">
        <div className="container mx-auto px-6 py-12 space-y-3">
          <h2 className="text-2xl font-light">La soluzione Cloud per il tuo centralino aziendale</h2>
          <p>Con la nostra soluzione ottieni la licenza per l’uso del software del centralino Voip (es. per un interno a solo 6 euro al mese), senza altre spese, prezzo chiaro e tondo, decidi tu quando disattivare il rinnovo, non ci sono vincoli!</p>
          <p><b>Questa soluzione non prevede l’hardware</b>, in quanto puoi utilizzare quello che hai già (cuffie o telefoni Voip). Oppure puoi acquistarli sul nostro shop online!</p>
        </div>
      </section>

      <section className="bg-white">
        <div className="container mx-auto px-6 py-12">
          <div className="flex flex-wrap gap-8">
            <div className="flex-1">
              <h3 className="text-xl">uCloud il centralino Voip in Cloud con tecnologia VOIspeed.</h3>
              <span className="block font-bold text-xl mb-3">Semplice, efficiente e con rapidità di utilizzo.</span>
              <ul className="list-inside list-disc">
                <li>E’ un prodotto TeamSystem</li>
                <li>E’ una soluzione di comunicazione integrata</li>
                <li>Semplice ed economico, utilizzi la tua rete, i tuoi telefoni e pc</li>
                <li>Tecnologia cloud, facile e lineare</li>
                <li>Puoi utilizzarlo con linee analogiche, ISDN e VOIP già presenti nella tua azienda</li>
                <li>Nessun vincolo con operatore telefonico</li>
                <li>Canone bimestrale (durata minima 12 mesi)</li>
                <li>Pagamento semplice e automatico tramite Paypal ricorrente</li>
              </ul>
            </div>
            <div className="lg:w-3/12">
              <StaticImage src="../images/sp_TS_TeamSystem_Communication_Color-1280x226.png" alt="software partner Team System" placeholder="blurred" />
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-8">
            <div className="bg-green-100 p-12">
              <span className="block text-4xl font-thin">La tecnologia VOIspeed ha molte funzioni. Ecco le più importanti!</span>
              <button onClick={openModal} className="uppercase bg-red-600 px-6 py-3 rounded-md hover:bg-red-700 transition-colors inline-flex mt-8 text-white">Richiedi subito un contatto</button>
            </div>

            <div>
              <ul className="flex flex-col">
                <li className="inline-flex"><CheckIcon className="h-6 w-6 text-green-500 mr-1" />Casella vocale/Voice mail</li>
                <li className="inline-flex"><CheckIcon className="h-6 w-6 text-green-500 mr-1" />Gestione avanzata gruppi di risposta</li>
                <li className="inline-flex"><CheckIcon className="h-6 w-6 text-green-500 mr-1" />Gestione illimitata del numero di linee telefoniche</li>
                <li className="inline-flex"><CheckIcon className="h-6 w-6 text-green-500 mr-1" />Totalmente indipendente dall'hardware grazie all'uso di gateway</li>
                <li className="inline-flex"><CheckIcon className="h-6 w-6 text-green-500 mr-1" />Interconnessione tra centralini (WAN)</li>
                <li className="inline-flex"><CheckIcon className="h-6 w-6 text-green-500 mr-1" />Risponditore automatico multilivello</li>
                <li className="inline-flex"><CheckIcon className="h-6 w-6 text-green-500 mr-1" />Report chiamate</li>
                <li className="inline-flex"><CheckIcon className="h-6 w-6 text-green-500 mr-1" />Rubrica di sistema condivisa</li>
                <li className="inline-flex"><CheckIcon className="h-6 w-6 text-green-500 mr-1" />Instradamento chiamate trasparente per l'utente</li>
                <li className="inline-flex"><CheckIcon className="h-6 w-6 text-green-500 mr-1" />Integrazione PBX</li>
              </ul>
            </div>

            <div>
              <ul className="flex flex-col">
                <li className="inline-flex"><CheckIcon className="h-6 w-6 text-green-500 mr-1" />Integrazione con Outlook</li>
                <li className="inline-flex"><CheckIcon className="h-6 w-6 text-green-500 mr-1" />Scorciatoie personalizzabili</li>
                <li className="inline-flex"><CheckIcon className="h-6 w-6 text-green-500 mr-1" />SMS tra operatori</li>
                <li className="inline-flex"><CheckIcon className="h-6 w-6 text-green-500 mr-1" />Chat multiutente</li>
                <li className="inline-flex"><CheckIcon className="h-6 w-6 text-green-500 mr-1" />Conference Call</li>
                <li className="inline-flex"><CheckIcon className="h-6 w-6 text-green-500 mr-1" />Multidevice</li>
                <li className="inline-flex"><CheckIcon className="h-6 w-6 text-green-500 mr-1" />Video presenza</li>
                <li className="inline-flex"><CheckIcon className="h-6 w-6 text-green-500 mr-1" />Gestione rapida con Drag&Drop</li>
                <li className="inline-flex"><CheckIcon className="h-6 w-6 text-green-500 mr-1" />File Transfer tra interni</li>
                <li className="inline-flex"><CheckIcon className="h-6 w-6 text-green-500 mr-1" />Registrazione delle chiamate</li>
              </ul>
            </div>

          </div>

        </div>
      </section>

      <section className="bg-blue-50" id="gui">
        <div className="container mx-auto px-6 py-12 space-y-8">
          <div className="flex gap-8 items-center flex-wrap">
            <div className="lg:w-1/2">
              <h2 className="text-2xl font-light ">Web UI HTML5 e Nuovo Client Desktop</h2>
              <span className="block text-4xl uppercase font-bold ">UNA NUOVA INTERFACCIA PER UNA MIGLIORE ESPERIENZA UTENTE.</span>
            </div>
            <div className="flex-1">
              <StaticImage className="rounded-xl" src="../images/voispeed-ui.jpg" alt="VOIspeed UI" placeholder="blurred" />
            </div>
          </div>

          <div className="space-y-3">
            <p>L’interfaccia grafica per tutti gli utenti che lavorano in azienda ed in ufficio: dalla centralista, al commerciale, dall’amministrativa al manager, dal magazziniere al titolare. Grazie ad essa l’utente ha a disposizione una miriade di strumenti di lavoro semplici da utilizzare e il controllo del centralino virtuale, a portata di click e indipendenti dalla tipologia di telefono che usa per parlare.</p>
            <p>La UI infatti è una console con la quale si fanno partire le chiamate, si fa la conferenza, si accede alla rubrica, ecc. ecc., che comunica con il PBX VOIspeed il quale usa qualunque tipo di terminale anche il telefono analogico più semplice) per ascoltare e parlare: al resto ci pensa la GUI. Non ci sono limitazioni alle periferiche da utilizzare; è possibile gestire contemporaneamente periferiche IP, USB o il proprio cellulare e passare da una periferica all’altra in maniera trasparente per l’interlocutore.</p>
          </div>
          <div className="flex gap-8 items-center flex-wrap-reverse">
            <div className="flex-1">
              <StaticImage className="rounded-xl" src="../images/voispeed-app.jpg" alt="VOIspeed App" placeholder="blurred" />
            </div>
            <div className="w-full lg:w-1/2">
              <h2 className="text-2xl font-light ">Utilizza il centralino anche in mobilità</h2>
              <span className="block text-4xl uppercase font-bold ">APP PER IOS E ANDROID.</span>
            </div>
          </div>

          <div className="space-y-3">
            <p>I tuoi servizi e il tuo interno ovunque. Con l’APP di VOIspeed UCloud il tuo cellulare diventa il tuo interno aziendale e puoi verificare lo stato degli interni, accedere alla rubrica aziendale, chattare con i tuoi colleghi e fare molto altro ovunque tu sia. L’APP di VOIspeed UCloud è uno strumento che offre, con immediatezza e semplicità di gestione, molti servizi avanzati per la comunicazione e la collaborazione aziendale oltre alla possibilità di gestire telefonate sia da softphone che tramite un cellulare.</p>
          </div>

        </div>
      </section>

      <footer className="bg-gray-900">
        <div className="container mx-auto px-6 py-12 text-white grid  lg:grid-cols-2">
          <div>
            <span className="text-xs block text-center lg:text-left">&copy; 2021 Cascinanet S.r.l.</span>
            <a href="https://www.iubenda.com/privacy-policy/8074034" target="_blank" className="text-xs block text-center lg:text-left" rel="noopener noreferrer">Privacy Policy</a>
            <a href="https://www.iubenda.com/privacy-policy/8074034/cookie-policy" target="_blank" className="text-xs block text-center lg:text-left" rel="noopener noreferrer">Cookie Policy</a>
          </div>

          <div>
            <span className="text-xs block text-center lg:text-right">Cascinanet S.r.l. | P.IVA e C.F. 02073620508 – R.E.A. 178835 | Cap. Sociale € 10.000 i.v.</span>
            <span className="text-xs block text-center lg:text-right">UCloud e VOIspeed sono marchi di proprietà di TeamSystem Communication S.r.l.</span>
          </div>
        </div>
      </footer>

    </main>
    </>
  )
}

export default IndexPage